import './style.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'vanilla-tilt';


jQuery(document).ready(function ($) {
    // Function to switch to the next image
    function switchToNextImage() {
      var $activeImage = $(".slider-img.active");
      var $nextImage = $activeImage.next(".slider-img");
      
      // If there is no next image, loop back to the first image
      if ($nextImage.length === 0) {
        $nextImage = $(".slider-img:first");
      }
      
      // Remove active class from all images and add it to the next image
      $(".slider-img").removeClass("active");
      $nextImage.addClass("active");
    }

    // Click event handler for slider images
    $(".slider-img").on("click", function () {
      $(".slider-img").removeClass("active");
      $(this).addClass("active");
    });

    // Automatically open the center image on page load
    $(".slider-img:nth-child(2)").click();

    // Automatically switch to the next image every two seconds
     setInterval(switchToNextImage, 2000);
  });


  var myCarousel = document.querySelector('#myCarousel')
  var carousel = new bootstrap.Carousel(myCarousel, {
    interval: 2000,
    wrap: false
  })





document.addEventListener('DOMContentLoaded', function() {

    const imagePaths = [
        "/img/page 1_.png",
        "/img/page 1_.png",
    ];

    const texts = [
        "Compete In Thrilling Cricket Matches And Climb The Leaderboard To Earn Rewards!",
        "Play The World’s First Socially Immersive Cricket Mobile Game",
    ];

    // Get references to the image and text elements
    const imageElement = document.getElementById('dynamic-image');
    const textElement = document.getElementById('dynamic-text');

    // Initialize index for image and text
    let currentIndex = 0;

    // Function to update image and text
    function updateContent() {
        // Set image source and text content based on current index
        imageElement.src = imagePaths[currentIndex];
        textElement.textContent = texts[currentIndex];

        // Increment index or reset if at the end of the array
        currentIndex = (currentIndex + 1) % imagePaths.length;
    }

    // Call updateContent function initially
    updateContent();

    // Set interval to update content every few seconds (e.g., every 2 seconds)
    setInterval(updateContent, 3000); // Change the time interval as needed
});





// Function to show sidebar
function showSidebar() {
    const sidebar = document.querySelector('.sidebar');
    sidebar.style.display = 'flex';
}

// Function to hide sidebar
function hideSidebar() {
    const sidebar = document.querySelector('.sidebar');
    sidebar.style.display = 'none';
}








